import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Container from "./components/Container";
import GlobalStyle from "./GlobalStyle";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <Container>
      <App />
    </Container>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
